import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../layouts/mdx';
import Search from '../components/Search';
export const _frontmatter = {
  "title": "Search"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Search mdxType="Search" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      